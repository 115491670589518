<template>
  <div v-if="showScreen">
    <v-card raised>
      <loading :active.sync="loading" />
      <v-dialog v-model="dialog" max-width="50%">
        <v-form ref="form" onSubmit="return false;">
          <v-card class="comments-view" style>
            <v-toolbar fixed flat>
              <v-toolbar-title>Change Password</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon
                  class="heading grey--text text--darken-4"
                  @click.prevent="closePopup"
                  >close</v-icon
                >
              </v-btn>
            </v-toolbar>
            <v-spacer>
              <v-container grid-list-md style="padding: 25px !important">
                Update Password for {{ user.name }} ({{ user.email }} -
                {{ user.userId }})
                <div class="body-2 red--text">
                  * indicated fields are mandatory
                </div>
                <v-layout wrap>
                  <v-flex md5>
                    <v-flex xs12 class="pr-4" sm6 md12>
                      <v-text-field
                        label="New Password *"
                        validate-on-blur
                        v-model="password"
                        type="password"
                        :rules="[rules.required, rules.password]"
                      />
                    </v-flex>
                    <v-flex xs12 class="pr-4" sm6 md12>
                      <v-text-field
                        label="Confirm Password *"
                        v-model="repeatPassword"
                        type="password"
                        validate-on-blur
                        :rules="[rules.required]"
                      />
                    </v-flex>
                  </v-flex>
                </v-layout>
                <v-btn
                  size="large"
                  color="orange darken-1"
                  @click.native="changePassword(user)"
                  style="color: white"
                  :loading="x.loading"
                  >Change Password</v-btn
                >
              </v-container>
            </v-spacer>
          </v-card>
        </v-form>
      </v-dialog>
      <!-- <v-progress-linear indeterminate color="trukkin-theme darken-2" v-show="loading"></v-progress-linear>-->
      <v-card-title class="bg-clr" style="padding: 16px !important">
        <v-layout column wrap>
          <div class="headline">User Management Section</div>
          <v-spacer />
          <div class="heading py-1 muted">All users with their user types</div>
        </v-layout>
        <v-spacer />
        <v-btn
          v-permissions="'create-user'"
          class="white--text"
          flat
          color="orange darken-1"
          @click="check()"
        >
          <v-icon>add</v-icon>&nbsp;Add New User
        </v-btn>
      </v-card-title>
      <v-layout style="border-top: 1px solid #efefef" class="p-16 m-t-10">
        <v-flex xs2 md3>
          <v-select
            v-model="searchTypes"
            :items="searchItems"
            flat
            label="Search Type"
            item-text="status"
            single-line
            class="pt-0"
            item-value="abbr"
            persistent-hint
            @change="searchByType()"
            height="10"
            return-object
            outline
          ></v-select>
        </v-flex>
        <v-flex xs2 md4 pr-3>
          <v-text-field
            height="55px"
            class="gaps"
            label="Search here.."
            outline
            :disabled="!searchTypes"
            single-line
            v-model="search"
            @keyup.enter="searchAnything"
            @click:append="searchAnything"
            append-icon="search"
          ></v-text-field>
        </v-flex>
        <!-- <v-flex xs2 md3 pr-3>
          <v-autocomplete
            label="Select User Type"
            :items="userTypeItems"
            multiple
            class="pt-0"
            style="padding-top: 12px !important; cursor: none !important"
            v-model="userType"
            small-chips
            @change="searchByUserType()"
            single-line
          />
        
        </v-flex> -->
        <v-flex xs2 md2>
          <v-select
            v-model="statusType"
            :items="statusTypeItems"
            label="Select Status Type"
            item-text="status"
            item-value="abbr"
            @change="searchByStatusType()"
            persistent-hint
            return-object
          ></v-select>
        </v-flex>
        <v-flex xs2 md2></v-flex>
        <v-flex xs1 class="reset-btn" v-show="resetBtn">
          <v-card-actions class="justify-center">
            <v-btn
              flat
              prepend-icon
              class="reset-btn-color"
              color="orange darken-1"
              @click.native="reset()"
            >
              Reset
              <v-icon color="orange">donut_large</v-icon>
            </v-btn>
          </v-card-actions>
        </v-flex>
      </v-layout>
      <v-layout class="p-16">
        <v-flex xs2 md12>
          <v-autocomplete
            label="Select User Type"
            :items="userTypeItems"
            multiple
            class="pt-0"
            deletable-chips
            style="
              padding-top: 0px !important;
              margin-top: 0px !important;
              cursor: none !important;
            "
            v-model="userType"
            small-chips
            @change="searchByUserType()"
            single-line
          >
          </v-autocomplete>
        </v-flex>
      </v-layout>
      <v-layout row wrap align-center>
        <v-flex md12 class="communication">
          <v-card class="nospace">
            <v-card-text class="nospace">
              <v-data-table
                id="jobsTable"
                hide-actions
                :headers="headers"
                style="word-break: break-all"
                :items="userItem"
                :disable-initial-sort="true"
                :pagination.sync="pagination"
              >
                <template slot="items" slot-scope="props">
                  <td>
                    {{ props.item.employeeID || "N.A" }}
                    <activityLogs
                      :type="'customer'"
                      :permissions="'view-logs-customer'"
                      :_id="props.item._id"
                      :id="props.item.employeeID"
                    />
                  </td>
                  <td v-if="props.item.countrySortName">
                    {{ checkWorkingCountry(props.item.countrySortName) }}
                  </td>
                  <td v-else>-</td>
                  <td>
                    <div>{{ props.item.name || "N.A" }}</div>
                    <div>{{ props.item.email || "N.A" }}</div>
                  </td>

                  <td>
                    <div>
                      {{ props.item.countryCode
                      }}{{ props.item.phoneNo || "N.A" }}
                    </div>
                  </td>
                  <td style="width: 10%">
                    <div>{{ props.item.country || "N.A" }}</div>
                  </td>
                  <td style="width: 10%">
                    <div>{{ getStatus(props.item.isBlocked) || "N.A" }}</div>
                  </td>
                  <td style="width: 10%">
                    <div>{{ props.item.userType || "N.A" }}</div>
                  </td>
                  <td style="width: 10%">
                    <div>{{ props.item.roleType || "N.A" }}</div>
                  </td>
                  <td>
                    <v-tooltip
                      top
                      v-if="!props.item.isBlocked"
                      content-class="tooltip-op"
                      color="orange darken-1"
                    >
                      <template slot="activator">
                        <v-icon
                          color="orange darken-1"
                          flat
                          v-if="props.item.userWeight >= weight"
                          prepend-icon
                          @click.native="checkEdit(props.item.userId)"
                          v-permissions="'edit-user'"
                          class="user-actions-management"
                          title="Edit User"
                          >create</v-icon
                        >
                      </template>
                      <span class="tooltip">Edit User</span>
                    </v-tooltip>
                    <v-tooltip
                      top
                      content-class="tooltip-op"
                      color="orange darken-1"
                    >
                      <template slot="activator">
                        <v-icon
                          color="orange darken-1"
                          flat
                          v-if="props.item.userWeight >= weight"
                          prepend-icon
                          @click.native="viewDetails(props.item.userId)"
                          class="user-actions-management"
                          title="View Details"
                          >visibility</v-icon
                        >
                      </template>
                      <span class="tooltip">View Details</span>
                    </v-tooltip>
                    <v-tooltip
                      top
                      v-if="
                        !props.item.isBlocked &&
                        props.item.userId != userId &&
                        props.item.userWeight >= weight
                      "
                      content-class="tooltip-op"
                      color="orange darken-1"
                    >
                      <template slot="activator">
                        <v-icon
                          color="orange darken-1"
                          flat
                          prepend-icon
                          v-permissions="'block-user'"
                          @click.native="
                            updateUser(props.item.userId, 'true', 'block')
                          "
                          class="user-actions-management"
                          title="Block User"
                          >block</v-icon
                        >
                      </template>
                      <span class="tooltip">Block User</span>
                    </v-tooltip>
                    <v-tooltip
                      top
                      v-if="
                        props.item.isBlocked &&
                        props.item.userId != userId &&
                        props.item.userWeight >= weight
                      "
                      content-class="tooltip-op"
                      color="orange darken-1"
                    >
                      <template slot="activator">
                        <v-icon
                          color="red darken-1"
                          flat
                          prepend-icon
                          v-permissions="'block-user'"
                          @click.native="
                            updateUser(props.item.userId, 'false', 'block')
                          "
                          class="user-actions-management"
                          title="Unblock User"
                          >clear</v-icon
                        >
                      </template>
                      <span class="tooltip">Unblock User</span>
                    </v-tooltip>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>
    <success-dialog
      :content="x.message"
      :show="x.success"
      :onclose="closeMessage"
    />
    <!-- <span class="page-count-span totalpages"
      >Total Pages - {{ totalPages }}</span
    > -->
    <!-- <PaginationButtons
      :url="paginationURL"
      :callback="callback"
      :action="`user`"
    /> -->

    <pagination
      :totalPages="totalPages"
      :pages="this.pages"
      @pageNo="page"
      :url="paginationURL"
      :callback="callback"
      :type="0"
      :componentKey="componentKey"
      :action="`user`"
    />

    <v-snackbar
      :timeout="6000"
      bottom
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
      >{{ x.error }}</v-snackbar
    >
  </div>
</template>

<script>
import { EventBus } from "../../event-bus.js";
import { ApiService } from "@/services/api-service";
import { userList } from "@/constants/datatable-headers.js";
import activityLogs from "../Common/activityLogs";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import Swal from "sweetalert2";
import ErrorBox from "@/components/Common/ErrorBox";
import SuccessDialog from "@/components/Common/SuccessDialog";
import PaginationButtons from "@/components/Pagination/PaginationButtons";
import { StorageKeys } from "../../constants/constants";
import removeLocalstorageMixin from "@/mixins/removeLocalstorageMixin";
import Loading from "vue-loading-overlay";
import pagination from "@/components/Pagination/pagination";
export default {
  mixins: [checkPermissionsMixin, removeLocalstorageMixin],
  components: {
    ErrorBox,
    pagination,
    SuccessDialog,
    activityLogs,
    Loading,
  },
  watch: {
    /*search: function(n, o) {
      if (n == "") {
        this.resetBtn = false;
        this.reset();
      } else {
        this.resetBtn = true;
      }
    },*/
    pagination(val) {
      let temp_url = "";
      if (val["sortBy"] === "user-type") {
        if (val["descending"]) {
          temp_url = "&sortType=-1&sortBy=name";
          this.updateQueryStringParameter("sortType", "-1");
          this.updateQueryStringParameter("sortBy", "name");
        } else {
          temp_url = "&sortType=1&sortBy=name";
          this.updateQueryStringParameter("sortType", "1");
          this.updateQueryStringParameter("sortBy", "name");
        }
      }
      if (!val["sortBy"]) {
        this.removeParam(["sortType", "sortBy"]);
      }

      //  if (!val["sortBy"]) {
      //   delete this.payload['sortType'];
      //   delete this.payload['sortBy'];
      //   this.componentKey = this.componentKey + 1;
      //   this.loading = true;
      // }
    },
  },
  created() {
    this.removeStorage();
    this.getAllUserType();
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);
      if (localStorage.getItem("paginationUrlUser") !== null) {
        this.updatePaginationUrl();
      }
      // this.search = "";
      // this.userType = [];
      // this.statusType = "All";
      // this.reset();
      // this.paginationURL = `/listAdmin?workingCountry=${this.workingCountry}&isSearch=false`;
    });
    this.search = "";
    if (localStorage.getItem("paginationUrlUser") !== null) {
      this.updatePaginationUrl();
    } else {
      this.paginationURL = `/listAdmin?workingCountry=${this.workingCountry}&isSearch=false`;
    }
    //this.paginationURL = `/listAdmin?workingCountry=${this.workingCountry}&isSearch=false`;

    if (localStorage.getItem("searchUser") !== null) {
      // localStorage.setItem("searchType", this.searchTypes);
      this.search = localStorage.getItem("searchUser");
      let searchTypes = JSON.parse(localStorage.getItem("searchUserType"));
      this.searchTypes = searchTypes;
    }
    if (localStorage.getItem("userTypeFilter") !== null) {
      this.userType = JSON.parse(localStorage.getItem("userTypeFilter"));
    }

    if (localStorage.getItem("userStatusType") !== null) {
      this.statusType = localStorage.getItem("userStatusType");
    }
    let permissions = JSON.parse(localStorage.getItem("permissions"));
    this.weight = permissions.userType.weight || 0;
    // this.getUsers();
  },
  data() {
    return {
      userText: [],
      searchItems: [
        { status: "Username", abbr: "userName" },
        { status: "Employee ID", abbr: "EmpId" },
        { status: "Phone Number", abbr: "mobileNumber" },
        { status: "Email", abbr: "Email" },
      ],
      statusTypeItems: [
        { status: "All", abbr: "All" },
        { status: "Active", abbr: "Active" },
        { status: "Block", abbr: "Block" },
      ],
      statusType: "All",
      userTypeItems: [],
      userType: [],
      searchTypes: "",
      showScreen: true,
      operationName: "",
      workingCountry: null,
      pagination: {},
      pages: 1,
      totalPages: "",
      weight: 0,
      resetBtn: true,
      paginationURL: ``,
      loading: true,
      userId: "",
      componentKey: 0,
      user: {},
      search: "",
      password: "",
      repeatPassword: "",
      dialog: false,
      payload: {},
      rules: {
        required: (value) => !!value || "This field is required.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        password: (v) =>
          /^.{6,}$/.test(v) || "Password should be minimum of 6 characters",
        phoneNumber: (v) =>
          /^(\d{5,16})$/.test(v) || "Please Enter Valid Mobile No.",
      },
      headers: userList,

      userItem: [],
      x: {
        error: null,
        message: null,
        success: false,
      },
    };
  },

  methods: {
    updateQueryStringParameter(key, value) {
      var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
      var separator = this.paginationURL.indexOf("?") !== -1 ? "&" : "?";
      if (this.paginationURL.match(re)) {
        this.paginationURL = this.paginationURL.replace(
          re,
          "$1" + key + "=" + value + "$2"
        );
      } else {
        this.paginationURL = this.paginationURL + separator + key + "=" + value;
      }
    },
    removeParam(keys) {
      let sourceURL = localStorage.getItem("paginationUrlUser");

      var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString =
          sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
      if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
          param = params_arr[i].split("=")[0];
          if (keys.indexOf(param) >= 0) {
            params_arr.splice(i, 1);
          }
        }
        rtn = rtn + "?" + params_arr.join("&");
      }
      this.paginationURL = rtn;
    },
    getAllUserType() {
      this.loading = true;
      ApiService.postDataAcl("/getWeightedUserType", {
        userId: localStorage.getItem("userId"),
      }).then(
        (response) => {
          response.data.data.forEach((e) => {
            this.userTypeItems.push({
              text: e.userTypeTitle,
              value: e._id,
            });
          });

          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Fetch User Types";
        }
      );
    },
    checkWorkingCountry(country) {
      var workingCountryArray = [];
      JSON.parse(this.workingCountry).forEach((key, index) => {
        if (country && country.includes(key)) {
          workingCountryArray.push(key);
        }
      });
      return workingCountryArray.toString();
    },
    async check() {
      this.operationName = "create-user";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.$router.push(`/add-users`);
      } else {
        return;
      }
    },
    async checkEdit(id) {
      this.operationName = "edit-user";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.editUser(id);
      } else {
        return;
      }
    },
    updatePaginationUrl() {
      let tempUrl = localStorage.getItem("paginationUrlUser");
      let x = tempUrl.split("&");
      let y = x[0].split("=");
      y[1] = this.workingCountry;
      x[0] = y.join("=");
      tempUrl = x.join("&");
      this.paginationURL = tempUrl;
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },
    goback() {
      this.$router.go(-1);
    },
    reset() {
      localStorage.removeItem("searchUser");
      localStorage.removeItem("paginationUrlUser");
      localStorage.removeItem("searchUserType"),
        localStorage.removeItem("userTypeFilter");
      localStorage.removeItem("userStatusType");

      this.search = "";
      this.searchTypes = "";
      this.userType = [];
      this.statusType = "All";
      let url = `/listAdmin?workingCountry=${this.workingCountry}&isSearch=false`;

      this.paginationURL = url;
    },

    searchByType() {
      this.$emit("filters-type", this.searchTypes.abbr);
      this.search = "";
    },
    async searchAnything() {
      this.search = this.search.trim();
      if (this.search.trim().length > 0) {
        localStorage.setItem("searchUser", this.search);
        localStorage.setItem(
          "searchUserType",
          JSON.stringify(this.searchTypes)
        );
        let url = `/listAdmin?workingCountry=${this.workingCountry}&isSearch=true&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
        if (this.statusType.abbr) {
          url = url + `&status=${this.statusType.abbr}`;
        }
        if (this.userType.length) {
          url = url + `&userType=${JSON.stringify(this.userType)}`;
        }
        this.paginationURL = url;
      } else {
        let url = `/listAdmin?workingCountry=${this.workingCountry}&isSearch=false`;
        if (this.statusType.abbr) {
          if (this.statusType.abbr == "All") {
            url = url;
          } else {
            url = url + `&status=${this.statusType.abbr}`;
          }
        }
        if (this.userType.length) {
          url = url + `&userType=${JSON.stringify(this.userType)}`;
        }
        this.paginationURL = url;
      }
    },
    async searchByUserType() {
      let url = "";
      if (this.userType.length) {
        localStorage.setItem("userTypeFilter", JSON.stringify(this.userType));
        url = `/listAdmin?workingCountry=${
          this.workingCountry
        }&isSearch=true&userType=${JSON.stringify(this.userType)}`;
      } else {
        url = `/listAdmin?workingCountry=${this.workingCountry}&isSearch=true}`;
      }
      // ----------- checking other filters and adding respectively to url
      if (this.search) {
        url =
          url +
          `&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
      }
      if (this.statusType.abbr) {
        if (this.statusType.abbr == "All") {
          url = url;
        } else {
          url = url + `&status=${this.statusType.abbr}`;
        }
      }
      this.paginationURL = url;
    },
    async searchByStatusType() {
      let url = "";
      if (this.statusType.abbr) {
        localStorage.setItem("userStatusType", this.statusType.abbr);
        if (this.statusType.abbr == "All") {
          url = `/listAdmin?workingCountry=${this.workingCountry}&isSearch=true`;
        } else {
          url = `/listAdmin?workingCountry=${this.workingCountry}&isSearch=true&status=${this.statusType.abbr}`;
        }
      }
      // ----------- checking other filters and adding respectively to url
      if (this.userType.length) {
        url = url + `&userType=${JSON.stringify(this.userType)}`;
      }
      if (this.search) {
        url =
          url +
          `&searchText=${this.search}&searchType=${this.searchTypes.abbr}`;
      }
      this.paginationURL = url;
    },

    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    getStatus(status) {
      if (status) {
        return "Blocked";
      } else {
        return "Active";
      }
    },
    viewDetails(id) {
      this.$router.push(`/user-management/${id}`);
    },
    page(event) {
      this.pages = event;
    },

    async getUserType(id) {
      const response = await ApiService.postDataAcl("/getUserDetails", {
        userId: id,
      });
      return response;
    },
    async callback({ list, totalData }) {
      this.userId = localStorage.getItem("userId");
      this.loading = true;
      this.items = list;
      let users = [...this.items];

      this.totalPages = Math.ceil(totalData / 15);
      this.pagination.rowsPerPage = 15;
      let ids = [];
      ids = users.map((x) => x.userId);
      let response = await this.getUserType(ids);
      let data = response.data.data;
      users = users.map((item) => ({
        ...item,
        ...data.find(({ userId }) => userId == item.userId),
      }));
      this.loading = false;
      this.userItem = users;
    },
    changePasswordPopup(user) {
      this.user = user;
      this.dialog = true;
    },
    closePopup() {
      this.user = {};
      this.dialog = false;
    },
    changePassword(user) {
      if (this.password == "") {
        this.processing = false;
        this.x.error = "Password is mandatory";
      } else if (this.password != this.repeatPassword) {
        this.processing = false;
        this.x.error = "Password should match with confirm password";
        return;
      } else if (this.password.length < 6) {
        this.processing = false;
        this.x.error = "Password should be minimum 6 characters";
        return;
      } else {
        this.updateUser(user.userId, "", "change-password");
      }
    },
    editUser(id) {
      this.$router.push(`/edit-user/${id}`);
    },

    async updateUser(id, status, _type) {
      this.operationName = "block-user";
      let y = await this.checkOpertaionPermission(this.operationName);

      if (y) {
        if (this.processing) {
          return;
        } else {
          this.processing = true;
          let blockUrl = "/admin/updateUser";
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };
          let body = {};
          let _status_ = "";
          if (_type == "block") {
            body = {
              userId: id.toString(),
              isBlocked: status,
            };
            if (status == "true") {
              _status_ = "User Blocked Successfully";
            } else {
              _status_ = "User Unblocked Successfully";
            }
          }
          if (_type == "change-password") {
            body = {
              userId: id.toString(),
              password: this.password,
            };
            _status_ = "Password Updated Successfully";
          }

          this.axios.post(this.constants.apiUrl + blockUrl, body, config).then(
            (response) => {
              this.x.success = true;
              this.componentKey = this.componentKey + 1;
              this.processing = false;
              this.password = "";
              this.repeatPassword = "";
              this.x.message = `${_status_}`;
              this.dialog = false;
            },
            (error) => {
              this.processing = false;
              this.x.error = error.response.data.message;
            }
          );
        }
      } else {
        return;
      }
    },
  },
};
</script>
<style lang="scss">
#jobsTable .theme--light.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
  color: white !important;
  margin-left: 10px !important;
}
#jobsTable .theme--light.v-datatable thead th.column.sortable.active .v-icon {
  opacity: 1 !important;
  color: white !important;
  margin-left: 10px !important;
}
#userType th,
#userRole th,
#userSubRole th {
  background: orange !important;
  font-weight: bold;
  padding: 0 15px;
}
#userType td,
#userRole td,
#userSubRole td {
  font-size: 16px !important;
  text-align: left;
  padding: 5px !important;
}

.card--raised {
  border: 1px solid orange !important;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

thead {
  background: #fbfbfb;
}
</style>
<style scoped>
.card-style {
  border-radius: 7px !important;
  border: 1px darkorange !important;
  padding: 10px !important;
}
.user-actions-management {
  padding-left: 10px;
}
.role {
  padding-left: 20px;
}
.theme--light.v-icon {
  cursor: pointer;
}
.v-toolbar {
  position: inherit !important;
}

.search {
  float: left;
}

.country {
  display: inline-block;
}
.m-t-10 {
  margin-top: 10px;
}

.filter {
  padding: 20px;
  margin: 0 auto;
}
.reset {
  margin-top: 10px;
}
.gaps {
  position: relative;
  right: 2px;
  z-index: auto;
}
.reset-btn-color {
  background-color: #ffe7c9 !important;
}
.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
}
</style>
